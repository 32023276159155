import { render, staticRenderFns } from "./settingUpAFullyManagedSite.vue?vue&type=template&id=3a11a749&scoped=true&"
import script from "./settingUpAFullyManagedSite.vue?vue&type=script&lang=js&"
export * from "./settingUpAFullyManagedSite.vue?vue&type=script&lang=js&"
import style0 from "./settingUpAFullyManagedSite.vue?vue&type=style&index=0&id=3a11a749&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a11a749",
  null
  
)

export default component.exports